import React from 'react';
import SectionFeature from '../SectionFeature/SectionFeature';
import SectionTestimonials from '../SectionTestimonials/SectionTestimonials';
import SectionClients from '../SectionClients/SectionClients';

const sectionComponents = {
  feature: SectionFeature,
  testimonials: SectionTestimonials,
  clients: SectionClients
};

const SectionRenderer = ({ data }) => (
  <>
    {data.map( (section, index) => {
      if( !section.type ) return null;
      const Section = sectionComponents[section.type];
      return (
        <Section {...section} key={index}/>
      )
    })}
  </>
);

export default SectionRenderer;
