import React, { useState } from 'react';
import { v4 } from 'uuid';
import Slider from "react-slick";
import Section from '../Section/Section';
import Icon from '../UI/Icon/Icon.js';
import Testimonial from '../UI/Testimonial/Testimonial';

import styles from './SectionTestimonials.module.scss';
import "slick-carousel/slick/slick.css";

const SectionTestimonials = ({ className, testimonials, ...props }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const settings = {
        fade: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (current, next) => setCurrentIndex(next)
    };

    let slider;

    return (
        <Section className={styles.section} {...props}>
            <div className={styles.inner}>
                <div className={styles.sliderControls}>
                    <button className={styles.sliderControl} type="button" onClick={e => slider.slickGoTo(currentIndex - 1)} aria-label="Previous">
                        <Icon name="arrow-left" />
                    </button>
                    <span className={styles.sliderCount}>{`${('0' + (currentIndex + 1)).slice(-2)} / ${('0' + testimonials.length).slice(-2)}`}</span>
                    <button className={styles.sliderControl} type="button" onClick={e => slider.slickGoTo(currentIndex + 1)} aria-label="Next">
                        <Icon name="arrow-right" />
                    </button>
                </div>

                <Slider ref={instance => slider = instance} {...settings}>
                    {testimonials.map(testimonial => (
                        <Testimonial data={testimonial} key={v4()}/>
                    ))}
                </Slider>
            </div>
        </Section>
    )
}

SectionTestimonials.propTypes = {

};

export default SectionTestimonials;
