import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import Section from '../Section/Section';
import Fade from 'react-reveal/Fade';
import styles from './SectionClients.module.scss';

const SectionClients = ({ className, title, items, ...props }) => {
    return (
        <Section className={styles.section} {...props}>
            <Fade bottom>  
                <div className={styles.inner}>
                    <h2 className={styles.title}>{title}</h2>

                    <Fade bottom cascade>
                        <ul className={styles.items}>
                            {items.map((item) => {
                                return (
                                    <li key={item.id}>
                                        {item.childImageSharp ?
                                            <Img className={styles.image} fluid={item.childImageSharp.fluid} alt="" />
                                            :
                                            <img src={item} alt="" loading="lazy"/>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    </Fade>
                </div>
            </Fade>  
        </Section>
    )
}

SectionClients.propTypes = {

};

export default SectionClients;
