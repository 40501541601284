import React from 'react';
import { themes } from "../../context/ThemeContext";
import Section from '../Section/Section';
import Hgroup from '../UI/Hgroup/Hgroup';
import ButtonLink from '../UI/ButtonLink/ButtonLink';
import AssetZenImage from '../UI/AssetZenImage/AssetZenImage';
import Fade from 'react-reveal/Fade';
import styles from './SectionFeature.module.scss';

const SectionFeature = ({
    name,
    title,
    desc,
    link,
    image,
    containerStyle,
    contentComponent: Content,
    ...other
}) => {
    const isCover = image.fit === 'cover';
    const theme = containerStyle === 'PRIMARY' ?  themes.DARK : themes.LIGHT;
    
    return (
        <Section className={styles.section} isUnstyled={true} theme={theme} containerStyle={containerStyle} {...other}>
            <div className={isCover ? styles.imgHolderCover : styles.imgHolder}>
                {image.id &&
                    <AssetZenImage className={styles.img} id={image.id} width="1600" height="800" style={isCover ? { position: 'absolute' } : null}/>
                }

                {image.src && !image.id &&
                    <img className={styles.img} style={{ objectPosition: image.position }} src={image.src.publicURL || image.src} alt="" loading="lazy"/>
                }

            </div>
            
            <Fade bottom>
                <div className={styles.body}>
                    <Hgroup lead={name} title={title} isReverse={containerStyle === 'PRIMARY'} TitleTag="h2"/>

                    {Content ?
                        <Content className={styles.description} content={desc}/>
                    :
                        <p className={styles.description}>{desc}</p>
                    }

                    { link && link.url &&
                        <ButtonLink className={styles.button} to={link.url} isReverse={containerStyle === 'PRIMARY'}>{link.text}</ButtonLink>
                    }
                </div>
            </Fade>
            
        </Section>
    )
}

SectionFeature.propTypes = {

};

export default SectionFeature;
